import React from "react"

import * as footerStyles from "./styles/footer.module.css"

const Hours = () => {
  const today = new Date().getDay()

  return (
    <div className={footerStyles.hours}>
      <span className={footerStyles.title}>Hours</span>
      <table
        vocab="https://schema.org/"
        property="openingHoursSpecification"
        typeof="OpeningHoursSpecification"
      >
        <tbody>
          <tr
            style={today === 5 ? { fontWeight: 600 } : {}}
            property="dayOfWeek"
            href="https://schema.org/Friday"
          >
            <td>Friday</td>
            <td>
              <time property="opens" content="11:00:00">
                11:00 AM
              </time>{" "}
              -{" "}
              <time property="closes" content="20:00:00">
                8:00 PM
              </time>
            </td>
          </tr>
          <tr
            style={today === 6 ? { fontWeight: 600 } : {}}
            property="dayOfWeek"
            href="https://schema.org/Saturday"
          >
            <td>Saturday</td>
            <td>
              <time property="opens" content="11:00:00">
                11:00 AM
              </time>{" "}
              -{" "}
              <time property="closes" content="20:00:00">
                8:00 PM
              </time>
            </td>
          </tr>
          <tr
            style={today === 0 ? { fontWeight: 600 } : {}}
            property="dayOfWeek"
            href="https://schema.org/Sunday"
          >
            <td>Sunday</td>
            <td>
              <time property="opens" content="11:00:00">
                11:00 AM
              </time>{" "}
              -{" "}
              <time property="closes" content="19:00:00">
                7:00 PM
              </time>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Hours
